import Users from 'app/models/Users';
import alertService from 'services/alertService';
import i18n from 'i18next';
import {
  ICreateRemoveDirectoryData,
  ICreateDirectoryResponse,
  ICreateFileResponse,
  IRepository,
  IRemoveFileData,
  IRenameFileData,
  IRenameDirectoryData,
  IRepositoryVersion,
  ICreateVersionData,
  ITutorialStatus,
  IErrorResponse,
  IFile,
  IDirectory,
  IAddMemberResponse,
  ITicket,
  IGetFileData,
  IRenameRepositoryData,
  IRepositoryGeneral,
  IMoveDirectoryData,
  IMoveFileData,
  IRemoveRepositoryVersion,
  IDownloadFileData,
  ITicketMessage,
} from 'types';

export const fetchRepositories = async (): Promise<any> => {
  try {
    const response = await Users.getRepositories();

    return response;
  } catch (error) {
    alertService.alert({ type: 'error', content: i18n.t('common.message.fetchRepositoriesFailed') });
    return [];
  }
};

export const createRepository = async (data: any): Promise<IRepository | IErrorResponse> => {
  try {
    const response = await Users.createRepository(data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
};

export const getRepositoryDetail = async (repositoryId: string): Promise<IRepository> => {
  try {
    const response = await Users.getRepositoryDetail(repositoryId);

    return response;
  } catch (error: any) {
    alertService.alert({ type: 'error', content: i18n.t('common.message.fetchRepositoryFailed') });
    return {} as IRepository;
  }
};

export const addRepositoryFile = async (
  repositoryId: string,
  data: FormData,
  onProgress?: (progress: number) => void,
  onSuccess?: (response: any) => void,
  onError?: (error: string) => void,
): Promise<void> => {
  Users.addRepositoryFile(repositoryId, data, onProgress, onSuccess, onError);
};

export const addRepositoryDirectory = async (
  repositoryId: string,
  data: ICreateRemoveDirectoryData,
): Promise<ICreateDirectoryResponse> => {
  try {
    const response = await Users.addRepositoryDirectory(repositoryId, data);

    return response;
  } catch (error) {
    console.error('Error adding repository directory:', error);
    return {} as ICreateDirectoryResponse;
  }
};

export const removeRepositoryFile = async (repositoryId: string, data: IRemoveFileData): Promise<boolean> => {
  try {
    const reposonse = await Users.removeRepositoryFile(repositoryId, data);

    if (reposonse?.success) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error removing repository file:', error);
    return false;
  }
};

export const removeRepositoryDirectory = async (
  repositoryId: string,
  data: ICreateRemoveDirectoryData,
): Promise<boolean> => {
  try {
    const reposonse = await Users.removeRepositoryDirectory(repositoryId, data);

    if (reposonse?.success) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error removing repository directory:', error);
    return false;
  }
};

export const updateRepositoryGeneral = async (repositoryId: string, data: IRenameRepositoryData): Promise<IRepositoryGeneral> => {
  try {
    const reposonse = await Users.updateRepositoryGeneral(repositoryId, data);

    return reposonse;
  } catch (error) {
    console.error('Error renaming repository file:', error);
    return {} as IRepositoryGeneral;
  }
};

export const renameRepositoryFile = async (
  repositoryId: string,
  data: IRenameFileData,
): Promise<ICreateFileResponse> => {
  try {
    const reposonse = await Users.renameRepositoryFile(repositoryId, data);

    return reposonse;
  } catch (error) {
    console.error('Error renaming repository file:', error);
    return {} as ICreateFileResponse;
  }
};

export const renameRepositoryDirectory = async (
  repositoryId: string,
  data: IRenameDirectoryData,
): Promise<ICreateDirectoryResponse> => {
  try {
    const reposonse = await Users.renameRepositoryDirectory(repositoryId, data);

    return reposonse;
  } catch (error) {
    console.error('Error renaming repository directory:', error);
    return {} as ICreateDirectoryResponse;
  }
};

export const updateRepositoryFile = async (
  repositoryId: string,
  data: FormData,
  onProgress?: (progress: number) => void,
  onSuccess?: (response: string) => void,
  onError?: (error: string) => void,
): Promise<void> => {
  Users.updateRepositoryFile(repositoryId, data, onProgress, onSuccess, onError);
};

export const createRepositoryVersion = async (
  repositoryId: string,
  data: ICreateVersionData,
): Promise<IRepositoryVersion | IErrorResponse> => {
  try {
    const response = await Users.createRepositoryVersion(repositoryId, data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
};

export const removeRepositoryVersion = async (repositoryId: string, data: IRemoveRepositoryVersion): Promise<boolean> => {
  try {
    const reposonse = await Users.removeRepositoryVersion(repositoryId, data);

    if (reposonse?.success) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error removing repository version:', error);
    return false;
  }
};

export const updateRepositoryTutorialsStatus = async (
  repositoryId: string,
  data: ITutorialStatus,
): Promise<boolean> => {
  try {
    const response = await Users.updateRepositoryTutorialsStatus(repositoryId, data);

    if (response?.tutorialStatus) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error updating repository tutorials status:', error);
    return false;
  }
};

export const moveRepositoryFile = async (
  repositoryId: string,
  data: IMoveFileData,
): Promise<IFile | IErrorResponse> => {
  try {
    const response = await Users.moveRepositoryFile(repositoryId, data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
};

export const moveRepositoryDirectory = async (
  repositoryId: string,
  data: IMoveDirectoryData,
): Promise<IDirectory | IErrorResponse> => {
  try {
    const response = await Users.moveRepositoryDirectory(repositoryId, data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
};

export const addRepositoryMember = async (
  repositoryId: string,
  body: any,
): Promise<IAddMemberResponse | IErrorResponse> => {
  try {
    const response = await Users.addMember(repositoryId, body);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
};
export const updateMemberRoles = async (
  repositoryId: string,
  body: any,
): Promise<any | IErrorResponse> => {
  try {
    const response = await Users.updateMemberRoles(repositoryId, body);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
};

export const updateApplyCurrent = async (repositoryId: string | undefined, data: IGetFileData): Promise<boolean> => {
  try {
    const response = await Users.updateApplyCurrent(repositoryId, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateApplyUpcoming = async (repositoryId: string | undefined, data: IGetFileData): Promise<boolean> => {
  try {
    const response = await Users.updateApplyUpcoming(repositoryId, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRepositoryTickets = async (repositoryId: string): Promise<ITicket[]> => {
  try {
    const response = await Users.getRepositoryTickets(repositoryId);

    return response;
  } catch (error) {
    console.error('Error fetching repository tickets:', error);
    return [];
  }
}

export const getRepositoryFile = async (repositoryId: string, data: IGetFileData): Promise<IFile | IErrorResponse> => {
  try {
    const response = await Users.getRepositoryFile(repositoryId, data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
}

export const downloadRepositoryFiles = async (repositoryId: string, data: IDownloadFileData): Promise<{ downloadUrl: string } | IErrorResponse> => {
  try {
    const response = await Users.downloadRepositoryFiles(repositoryId, data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
}

export const getTicketsChats = async (repositoryId: string, ticketId: string): Promise<ITicketMessage[]> => {
  try {
    const response = await Users.getTicketsChats(repositoryId, ticketId);

    return response;
  } catch (error) {
    console.error('Error fetching tickets chats:', error);
    return [];
  }
}

export const createTicketMessage = async (repositoryId: string, ticketId: string, data: FormData): Promise<ITicketMessage | IErrorResponse> => {
  try {
    const response = await Users.createTicketMessage(repositoryId, ticketId, data);

    return response;
  } catch (error: any) {
    return {
      ...error.body,
      isError: true,
    } as IErrorResponse;
  }
}
